// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Omomurewa George-Ashiru",
  title: "Hi all, I'm Murewa",
  subTitle: emoji(
    "A woman in tech with experience in software development, business analysis and technical writing."
  ),
  // resumeLink:
  //   "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/murewaashiru",
  linkedin: "https://www.linkedin.com/in/murewageorge-ashiru/",
  gmail: "rachelleashiru@gmail.com",
  medium: "https://medium.com/@murewaashiru",
  blog: "https://blog.murewaashiru.com/",
  twitter:"https://twitter.com/Rachael_xx",
  hashnode:"https://blog.murewaashiru.com/",
  website:"https://murewaashiru.com/",
  // Instagram are also supported in the links!
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  // subTitle: "Woman in tech",
  skills: [
    emoji( "⚡ Develop backend end applications" ),
    emoji("⚡ Technical writing/documentation - articles, API documentation, user guides and more"),
    emoji( "⚡ Integration of third party services" ), 
    emoji( "⚡ Manage the delivery of projects" )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "mySQL, mongoDB, PostgreSQL",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "jira",
      fontAwesomeClassname: "fab fa-jira"
    },
    {
      skillName: "trello",
      fontAwesomeClassname: "fab fa-trello"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Covenant University",
      logo: require("./assets/images/cuLogo.png"),
      subHeader: "Bachelor of Science in Management Information System",
      duration: "August 2014 - July 2018",
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "JavaScript", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Node.js",
      progressPercentage: "70%"
    },
    {
      Stack: "SQL",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "IT Business Analyst",
      company: "Stanbic IBTC",
      companylogo: require("./assets/images/facebookLogo.png"),
      date: "June 2018 – Present",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      role: "Business Analyst",
      company: "AppZone Group",
      companylogo: require("./assets/images/quoraLogo.png"),
      date: "May 2017 – May 2018",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      role: "Software Developer Trainee",
      company: "AppZone Group",
      companylogo: require("./assets/images/airbnbLogo.png"),
      date: "Jan 2015 – Sep 2015",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }
  ]
};

// Volunteer experience section

const volunteerExperiences = {
  title:"volunteer",
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Technical Coordinator, Technical Writing",
      company: "SheCodeAfrica",
      companylogo: require("./assets/images/sheCodeAfricaLogo.svg"),
      date: "2021 – 2021",
      desc: "Empowering women in tech in Africa.",
      descBullets: [
        "Create and grade tasks for the Levelled Membership program",
        "Provide guidance for technical writers"
      ]
    },
    {
      role: "Technical Mentor, Backend Development",
      company: "Zuri Team",
      companylogo: require("./assets/images/zuri Team.png"),
      date: "2021 – Present",
      desc: "Building tech capacity.",
      descBullets: [
        "Grade tasks assigned to interns.",
        "Provide technical and career guidance to interns"
      ]
    }]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "SOME PROJECTS I'VE DABBLED IN",
  projects: [
    {
      image: require("./assets/images/agroFarmsLogo.png"),
      projectName: " ",
      projectDesc: "AgroFarm is a platform that connects farmers and customers together for the purpose of raising interest-bearing capital for farmers.",
      footerLink: [
        {
          name: "Read about it",
          url: "https://blog.murewaashiru.com/designing-the-backend-system-of-an-agritech-app"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/ZuriTalent.PNG"),
      projectName: " ",
      projectDesc: "ZuriTalent is a platform that connects tech job seekers with employment opportunities",
      // footerLink: [
      //   {
      //     name: "Visit Website",
      //     url: "http://nextu.se/"
      //   }
      // ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt. View my blog ",
  readMore: "https://blog.murewaashiru.com/",
  blogs: [
    {
      url: "https://blog.murewaashiru.com/the-imposter-syndrome-a-mental-plague",
      title: "The Imposter Syndrome: A Mental Plague",
      description:
        `Looking within and comparing with the expectation of the outside, you sense a gap. A gap in terms of skills, look, behaviour, 
        status and many more. Dwelling in this gap created the concept of the Imposter Syndrome`
    },
    {
      url: "https://blog.murewaashiru.com/payment-service-integration-200-countries-covered",
      title: "Payment Service Integration- 200 countries covered",
      description:
        `Handling payments for goods and services rendered back in the days was a very long and tedious process, 
        prone to errors and time-consuming. As technology evolved over the years, the financial sector also joined the ride....
        `
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: "SOME OF THE THINGS I'VE SPOKEN ON",
  // subtitle: emoji(
  //   "SOME OF THE THINGS I'VE SPOKEN ON"
  // ),

  talks: [
    {
      title: "Intro to Web Development with Node.js",
      subtitle: "AfriCode AfricodeX Session : Intro to Web Development with Node.js",
      // slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.africode.org/africodex-events/intro-to-nodejs"
    },
    {
      title: "How to structure your articles as a beginner in technical writing",
      subtitle: "SheCodeAfrica session: How to structure your articles as a beginner in technical writing",
      slides_url: "https://speakerdeck.com/murewaashiru/how-to-structure-your-articles-as-a-beginner-in-technical-writing",
      // event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  email_address: "rachelleashiru@gmail.com",
  display: true // Set true to display this section, defaults to false
};

// Twitter Section

const twitterDetails = {
  userName: "rachael_xx", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  volunteerExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
